.slickySlider {
  width: 100%;
}

.slickySlider .slideImage {
  width: 100%;
}
.searchResult {
  z-index: 1;
  min-height: 700px;
  max-width: 1280px;
  margin: auto;
  padding: 0 20px 100px 20px;
  position: relative;
}

.vehicleGroupcard {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  transition: 0.3s;
  width: 100%;
}

.groupContentWrap{
  padding: 0;
}
@media(max-width: 959px){
  .vehicleGroupcard{
    padding: 0;
    margin-bottom:0;
    flex-direction: column;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.08);
  }
  .groupContentWrap{
    padding: 10px;
  }
}

.leftBorder {
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  width: 4px;
  background-color: #2a9cb7;
  transition: 0.3s;
}
.groupIconOuterWrap{
  width: 120px;
  padding: 0;
}
@media(max-width:959px){
  .groupIconOuterWrap{
    width: 100%;
    padding: 10px;
  }
}
.groupIconWrap,
.vehicleIconWrap {
  height: 120px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #a0a0a0;
  display: flex;
  align-items: center;
}
.groupIconWrap{
  position: relative;
  overflow: hidden;
}
.vehicleIconWrap{
  width: 120px;
}
.groupIconWrap img,
.vehicleIconWrap img {
  width: 100%;
  height:100%;
}

.groupIconWrap.mobile {
  margin: 0;
}

.groupInfoWrap,
.vehicleInfoWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px 0 5px 20px;
  justify-content: space-between;
}
.vehicleInfoOuterContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:flex-start;
}
@media (max-width: 959px){
  .groupInfoWrap{
    padding: 0;
  }
  .groupIconWrap{
    width:100%;
    height: 175px;
  }
  .vehicleInfoOuterContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-end;
  }
}
.vehicleInfoContainer{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.vehicleInfoContainer h3{
  margin:0;
}

.vehicleFeatureWrap {
  display: flex;
  flex-direction:row;
  padding-left: 15px;
  flex: 1;
  justify-content: flex-end;
  align-items:flex-start;
  flex-wrap: wrap;
}
@media (max-width: 1280px){
  .vehicleInfoContainer{
    margin-top: 10px;
  }
  .groupIconWrap{
    align-items: flex-end;
  }
}
@media (max-width: 959px){
  .vehicleInfoContainer{
    width:100%;
  }
  .vehicleFeatureWrap{
    padding-left: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    row-gap: 5px;
    width:100%;
  }
}

.vehicleFeature {
  font-size: var(--font-small);
  margin-right: 25px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  font-weight: 800;
}

.vehicleFeature.mobile {
  margin: 0;
  margin-right: 10px;
  margin-top:5px;
  display: inline-block;
}

.vehicleFeature:last-child {
  margin-right: 15px;
}

@media (max-width: 959px){
  .vehicleFeature:last-child{
    margin-right: 0;
  }
}
.vehiclePrice {
  margin-right: 15px;
  display: flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
@media (max-width: 959px){
  .vehiclePrice{
    margin-right: 0;
    flex-direction: column;
  }
  
}
.vehiclePrice.mobile {
  min-width: initial;
  justify-content: initial;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}
.infoIcon{
  display:inline;
}
.strikedPrice {
  text-decoration: line-through;
  color: #848484;
  font-size: var(--font-medium);
  padding-right: 10px;
}

.currentPrice {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.15rem;
  line-height: 0.9;
  display: block;
}

.showMoreButton {
  margin-left: -4px;
  padding: 6px 6px;
  padding-right: 2px;
  display: flex;
  border-radius: 6px;
  font-size: var(--font-small);
  background-color: rgb(248, 249, 251);
  color: var(--brand-accent);
  font-weight: 700;
  cursor: pointer;
  -webkit-user-select: none;
}

.vehiclesWrap {
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px 40px;
  border-top: 1px solid #f6f6f6;
}

.vehicleCard {
  display: flex;
  padding: 5px 0;
}

.vehicleIconWrap {
  width: 120px;
  height: 100px;
}

.vehicleInfoWrap {
  justify-content: space-around;
}

.vehicleSelectButtonWrap {
  display: flex;
  align-items: center;
}

.vehicleQuantitySelectionWrap{
  display:flex;
  flex-direction: row;
  justify-items: space-between;
  align-items: center;
  margin-top: 0
}
@media (max-width: 959px){
  .vehicleQuantitySelectionWrap{
    padding: 10px;
    background-color: #e1e1e1;
  }
}
@media (max-width: 700px) and (min-width: 600px){
  .vehicleQuantitySelectionWrap{
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    margin-top: 5px;
    gap: 10px;
  }
}
@media (max-width:350px){
  .vehicleQuantitySelectionWrap{
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    margin-top: 5px;
    gap: 10px;
  }
}
.buttonStyledCheck:before {
  content: "Select";
  -webkit-appearance: none;
  background-color: #2a9cb7;
  width: 158px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 8px;
  color: #fff;
  position: relative;
  margin-left: -40px;
  cursor: pointer;
}

.vehicleSelectCheck:checked + .buttonStyledCheck:before {
  content: "Remove";
  background-color: #fddfdc;
  color: #e00101;
  font-weight: initial;
}

.seachFilter {
  width: 360px;
  display: flex;
  padding-right: 20px;
  flex-direction: column;
}

.filterView {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex: 1;
  margin-bottom: 15px;
}

.vehicleResult {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.filterCriteria {
  padding: 0 20px 0 30px;
  margin-top: 20px;
}

.filterCriteria h5 {
  margin: 0;
  margin-bottom: 10px;
}

.filterCriteriaItem {
  display: flex;
  align-items: center;
}

.filterCriteriaItem p {
  padding-left: 10px;
  font-size: var(--font-medium);
  margin: 8px 0;
}

.blueTextButton {
  font-size: var(--font-small);
  color: var(--primary-color);
  font-weight: 700;
  cursor: pointer;
  margin: 5px 0;
}

.priceName {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
}

.sortAction {
  position: relative;
  z-index: 100;
}

.sortButton {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 3px 5px 3px 2px;
  transition: 0.2s;
  cursor: pointer;
}

.sortButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.sortOptions {
  background-color: #fff;
  padding: 8px 0px;
  position: absolute;
  top: 25px;
  right: 0;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  display: none;
  transition: 0.2s;
  overflow: hidden;
}

.sortOptions p {
  margin: 0;
  padding: 6px 20px;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}

.sortOptions p:hover {
  background-color: #f0f0f0;
}

.sortAction:hover .sortOptions {
  display: block;
}

.disableRentals {
  opacity: 0.4;
  pointer-events: none;
}
.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  max-height: 50%;
}

.sliderImageWrapper {
  text-align: center;
}

.subscriptionCarMain {
  border-top-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px !important;
}

.filterBottomSheet{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  box-shadow: 0 1px 10px gray;
  width:100%;
  left:0;
  max-height: 0;
  border-radius: 10px 10px 0 0 ;
  z-index: 1000;
  background-color: white;
  overflow: hidden;
  transition: all 250ms ease-in-out;
}
.filterBottomSheet.activeBottomSheet{
  max-height: 500px;
}